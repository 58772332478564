import { Avatar, Button, Menu, Modal, Stack } from '@mantine/core'
import { IconLogout, IconSettings, IconUser } from '@tabler/icons'
import Cookies from 'js-cookie'
import { signOut, useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { initials } from '../../utils/fns'
import CountryInput from '../inputs/country_input'
import LanguageInput from '../inputs/language_input.js'
import PlatformInput from '../inputs/platform_input'

const flickshowPurple = '#7B39B6'

const locales = {
  ID: [
    { label: 'Bahasa Indonesia', value: 'id' },
    { label: 'English', value: 'en' }
  ], // Indonesia
  AR: [
    { label: 'Español', value: 'es' },
    { label: 'English', value: 'en' }
  ], // Argentina
  IT: [
    { label: 'Italiano', value: 'it' },
    { label: 'English', value: 'en' }
  ], // Italy
  PE: [
    { label: 'Español', value: 'es' },
    { label: 'English', value: 'en' }
  ], // Peru
  GB: [
    { label: 'English', value: 'en' },
    { label: 'English', value: 'en' }
  ], // United Kingdom
  KR: [
    { label: '한국어', value: 'ko' },
    { label: 'English', value: 'en' }
  ], // South Korea
  TH: [
    { label: 'ไทย', value: 'th' },
    { label: 'English', value: 'en' }
  ], // Thailand
  CO: [
    { label: 'Español', value: 'es' },
    { label: 'English', value: 'en' }
  ], // Colombia
  TR: [
    { label: 'Türkçe', value: 'tr' },
    { label: 'English', value: 'en' }
  ], // Turkey
  US: [
    { label: 'English', value: 'en' },
    { label: 'Español', value: 'es' }
  ], // United States (English and Spanish)
  CA: [
    { label: 'English', value: 'en' },
    { label: 'Français', value: 'fr' }
  ], // Canada (English and French)
  FR: [
    { label: 'Français', value: 'fr' },
    { label: 'English', value: 'en' }
  ], // France
  BR: [
    { label: 'Português', value: 'pt' },
    { label: 'English', value: 'en' }
  ], // Brazil
  DE: [
    { label: 'Deutsch', value: 'de' },
    { label: 'English', value: 'en' }
  ], // Germany
  ES: [
    { label: 'Español', value: 'es' },
    { label: 'English', value: 'en' }
  ], // Spain
  MX: [
    { label: 'Español', value: 'es' },
    { label: 'English', value: 'en' }
  ], // Mexico
  AU: [
    { label: 'English', value: 'en' },
    { label: 'English', value: 'en' }
  ], // Australia
  CL: [
    { label: 'Español', value: 'es' },
    { label: 'English', value: 'en' }
  ], // Chile
  IN: [
    { label: 'हिन्दी', value: 'hi' },
    { label: 'English', value: 'en' }
  ] // India (Hindi and English)
}

const LoggedInMenu = () => {
  const { data: session } = useSession()

  useEffect(() => {
    console.log('User session:', session)
  }, [session])

  const [modalOpened, setModalOpened] = useState(false)
  const router = useRouter()
  const t = useTranslations('Navbar')
  const country = router.locale?.split('-')[1].toLowerCase()
  const language = router.locale?.split('-')[0]
  const [selectedCountry, setSelectedCountry] = useState(country)
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    // Si el idioma ya existente en el locale no está en la lista de idiomas del país, selecciona el primero
    const currentLanguages = locales[country.toUpperCase()].map((l) => l.value)
    return currentLanguages.includes(language) ? language : currentLanguages[0]
  })
  const [selectedPlatforms, setSelectedPlatforms] = useState([])

  const hitsCountries = [
    'ID',
    'AR',
    'IT',
    'PE',
    'GB',
    'KR',
    'TH',
    'CO',
    'TR',
    'US',
    'CA',
    'FR',
    'BR',
    'DE',
    'ES',
    'MX',
    'AU',
    'CL',
    'IN'
  ]

  const platforms = [
    { value: 'Netflix', label: 'Netflix' },
    { value: 'Disney', label: 'Disney' },
    { value: 'Hulu', label: 'Hulu' },
    { value: 'Max', label: 'Max' },
    { value: 'Amazon Prime Video', label: 'Amazon Prime Video' }
  ]

  useEffect(() => {
    // Cargar país seleccionado del contexto
    setSelectedCountry(country)

    // Cargar plataformas guardadas en localStorage
    const storedPlatforms = localStorage.getItem('selectedPlatforms')
    if (storedPlatforms) {
      setSelectedPlatforms(JSON.parse(storedPlatforms))
    }
  }, [country])

  useEffect(() => {
    const currentLanguages = locales[selectedCountry.toUpperCase()].map(
      (l) => l.value
    )
    setSelectedLanguage((prev) => {
      return currentLanguages.includes(prev) ? prev : currentLanguages[0]
    })
  }, [selectedCountry])

  const handleCountryChange = (newCountry) => {
    setSelectedCountry(newCountry)
  }

  const handlePlatformChange = (selected) => {
    setSelectedPlatforms(selected)
    localStorage.setItem('selectedPlatforms', JSON.stringify(selected))
  }

  const onClick = () => {
    setModalOpened(false)
    const newLocale = `${selectedLanguage}-${selectedCountry.toUpperCase()}`
    //set NEXT_LOCALE cookie
    // Check if the user has consented to functional cookies
    const consent = Cookies.get('cookie_consent') // Assuming you have saved consent here

    if (consent) {
      const consentObj = JSON.parse(consent) // Parse the stored JSON consent object

      // Only set the locale cookie if the user has consented to functional cookies
      if (consentObj.functional) {
        document.cookie = `NEXT_LOCALE=${newLocale}; path=/; max-age=31536000`
      }
    }
    if (router.locale !== newLocale) {
      router.push(router.asPath, router.asPath, { locale: newLocale })
    }
  }

  return (
    <div
      style={{
        padding: 0, // Remueve el padding
        margin: 0, // Remueve el margen
        overflowX: 'hidden' // Previene cualquier desbordamiento horizontal
      }}
    >
      <Menu width={200}>
        <Menu.Target>
          <Avatar
            radius="xl"
            src={session?.user?.image || null} // Añadido para usar la imagen de perfil si está disponible
            referrerPolicy="no-referrer" // Añadido para controlar la política de referenciador
            sx={{
              backgroundColor: flickshowPurple,
              '&:hover': {
                cursor: 'pointer'
              }
            }}
          >
            {!session?.user?.image &&
              initials(session?.user?.name || session?.user.email).toUpperCase()}
          </Avatar>
        </Menu.Target>
        <Menu.Dropdown
          sx={{
            '@media (max-width: 480px)': {
              position: 'absolute',
              top: '100%',
              right: '10px',
              width: 'auto',
              maxWidth: '150px',
              left: 'auto'
            }
          }}
        >
          <Menu.Item disabled>
            Hey {session?.user?.name || session?.user?.email?.split('@')[0]}!
          </Menu.Item>

          <Menu.Divider />
          <Link href="/profile">
            <Menu.Item icon={<IconUser size={14} />}>Profile</Menu.Item>
          </Link>
          <Menu.Item
            icon={<IconSettings size={14} />}
            onClick={() => setModalOpened(true)}
          >
            {t('settings')}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            onClick={() => signOut({ redirect: false })}
            icon={<IconLogout size={14} />}
          >
            Log Out
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        title={t('settings')}
        centered
        size="md"
      >
        <Stack spacing={24} align="center">
          <CountryInput
            sx={{
              width: '80%',
              fontSize: '10px'
            }}
            label={t('country')}
            onChange={handleCountryChange}
            value={selectedCountry}
            filterCountries={hitsCountries}
          />
          <LanguageInput
            language={selectedLanguage}
            label={t('language')}
            setLanguage={setSelectedLanguage}
            options={locales[selectedCountry.toUpperCase()] || []}
            sx={{
              width: '80%'
            }}
            withinPortal={true}
          />
          <PlatformInput
            platforms={platforms}
            value={selectedPlatforms}
            onChange={handlePlatformChange}
            label={t('platforms')}
            withinPortal={true}
          />
          <Button sx={{ backgroundColor: '#7B39B6' }} w={'80%'} onClick={onClick}>
            {t('save')}
          </Button>
        </Stack>
      </Modal>
    </div>
  )
}

export default LoggedInMenu

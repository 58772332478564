import {
  Autocomplete,
  Box,
  Divider,
  Group,
  Loader,
  Overlay,
  Stack,
  Text
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { IconSearch } from '@tabler/icons'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React, { forwardRef, useRef, useState } from 'react'
import useSWR from 'swr'

export const trimText = (text, length) => {
  if (!text) return ''
  if (text.length > length) {
    return text.substring(0, length) + '...'
  }
  return text
}

export const SelectItem = forwardRef(
  ({ thumbnail, name, year, type, uid, inputRef, onClick, ...others }, ref) => {
    const handleClick = (e) => {
      if (type === 'Person') {
        e.preventDefault()
        if (onClick) {
          onClick(name)
        }
      } else if (inputRef && inputRef.current) {
        inputRef.current.blur()
      }
    }

    return type === 'Person' ? (
      <Box
        ref={ref}
        {...others}
        onClick={handleClick}
        sx={(theme) => ({
          cursor: 'pointer',
          transition:
            'transform 0.2s ease, background-color 0.2s ease, box-shadow 0.2s ease',
          '&:hover': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.dark[5]
                : theme.colors.gray[2],
            boxShadow: theme.shadows.sm,
            transform: 'scale(1.03)'
          },
          borderRadius: theme.radius.sm,
          padding: 6
        })}
      >
        <Group noWrap>
          <Box
            sx={{
              width: 30, // Fixed size for the circular image
              height: 30,
              borderRadius: '50%', // Circular shape
              overflow: 'hidden', // Ensures the image doesn't overflow
              position: 'relative'
            }}
          >
            <Image
              src={`https://wsrv.nl/?url=${thumbnail}&w=100&output=webp&ll`}
              alt="person"
              fill // Next.js feature to make the image responsive
              style={{
                objectFit: 'cover' // Ensures image maintains aspect ratio and fills the circular area
              }}
            />
          </Box>
          <Stack spacing={0}>
            <Text size="sm">{trimText(name, 40)}</Text>
          </Stack>
        </Group>
      </Box>
    ) : (
      <Link href={`/${type.toLowerCase()}/${uid}`} passHref>
        <Box
          ref={ref}
          {...others}
          sx={(theme) => ({
            transition:
              'transform 0.2s ease, background-color 0.2s ease, box-shadow 0.2s ease',
            '&:hover': {
              backgroundColor:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[5]
                  : theme.colors.gray[2],
              boxShadow: theme.shadows.sm,
              transform: 'scale(1.03)'
            },
            borderRadius: theme.radius.sm,
            padding: 6
          })}
        >
          <Group noWrap>
            <Image
              src={`https://wsrv.nl/?url=${thumbnail}&w=100&output=webp&ll`}
              width={40}
              height={60}
              alt="poster"
              style={{ borderRadius: '4px' }}
            />
            <Stack spacing={0}>
              <Text size="sm">{trimText(name, 40)}</Text>
              {year && (
                <Text size="xs" c="dimmed">
                  {type}, {year}
                </Text>
              )}
            </Stack>
          </Group>
        </Box>
      </Link>
    )
  }
)

SelectItem.displayName = 'SelectItem'
SelectItem.propTypes = {
  thumbnail: PropTypes.string,
  name: PropTypes.string,
  year: PropTypes.string,
  type: PropTypes.string,
  uid: PropTypes.string,
  inputRef: PropTypes.object,
  onClick: PropTypes.func
}

const DropdownComponent = ({
  movies = [],
  people = [],
  inputRef,
  searchTerm,
  onSubmit
}) => {
  const handlePersonClick = (personName) => {
    onSubmit({ preventDefault: () => {} }, personName)
  }

  const router = useRouter()
  const language = router.locale.split('-')[0] // Extract language from locale

  return (
    <Stack p={6} sx={{ width: '100%' }}>
      {/* Movies & TV Shows */}
      <Box>
        <Text
          size="sm"
          weight={500}
          color="white"
          mb={8}
          sx={{ marginLeft: '15px', marginTop: '5px' }}
        >
          MOVIES & TV SHOWS
        </Text>
        <Divider mb={8} />
        {movies.length > 0 ? (
          <Stack spacing={4}>
            {movies.map((item, index) => {
              const key = `title_${language}`
              const title = item[key] || item.title || 'No title available'

              return (
                <React.Fragment key={item.slug}>
                  <SelectItem
                    thumbnail={item.poster}
                    name={title}
                    year={
                      item.release_date
                        ? new Date(item.release_date).getFullYear().toString()
                        : ''
                    }
                    type={item.type}
                    uid={item.slug}
                    inputRef={inputRef}
                  />
                  {index !== movies.length - 1 && <Divider my={4} />}
                </React.Fragment>
              )
            })}
          </Stack>
        ) : (
          <Text size="xs" color="dimmed">
            No results found
          </Text>
        )}
      </Box>

      {/* People */}
      <Box mt={16}>
        <Text
          size="sm"
          weight={500}
          color="white"
          mb={8}
          sx={{ marginLeft: '15px', marginTop: '5px' }}
        >
          PEOPLE
        </Text>
        <Divider mb={8} />
        {people.length > 0 ? (
          <Stack spacing={4}>
            {people.slice(0, 3).map((person) => (
              <React.Fragment key={person.name}>
                <SelectItem
                  thumbnail={person.image}
                  name={person.name}
                  type="Person"
                  inputRef={inputRef}
                  onClick={handlePersonClick}
                />
              </React.Fragment>
            ))}
          </Stack>
        ) : (
          <Text size="xs" color="dimmed">
            No results found
          </Text>
        )}
      </Box>

      {/* Footer */}
      {searchTerm && (
        <Box mt={12} pt={6} sx={{ borderTop: '1px solid #444' }}>
          <Text
            size="xs"
            color="#7b39b6"
            align="center"
            weight={1000}
            sx={{ cursor: 'pointer' }}
            onClick={(e) => onSubmit(e)}
          >
            {`SEE ALL RESULTS FOR "${searchTerm.toUpperCase()}"`}
          </Text>
        </Box>
      )}
    </Stack>
  )
}

DropdownComponent.propTypes = {
  movies: PropTypes.array,
  people: PropTypes.array,
  inputRef: PropTypes.object,
  searchTerm: PropTypes.string,
  onSubmit: PropTypes.func
}

DropdownComponent.defaultProps = {
  movies: [],
  people: [],
  searchTerm: '',
  onSubmit: () => {}
}

const SearchInput = ({ label, width = '100%' }) => {
  const [value, setValue] = useState('')
  const [debounced] = useDebouncedValue(value, 200)
  const router = useRouter()
  const t = useTranslations('Navbar')

  const language = router.locale.split('-')[0]
  const languageQuery = ['es', 'pt'].includes(language)
    ? `&language=${language}`
    : ''

  const inputRef = useRef(null)
  const [isFocused, setIsFocused] = useState(false)

  const url = `/api/api_ext/content/multi_search?&type=all&sort=popularity&sort_order=desc${languageQuery}&q=`
  const fetcher = async (url) => {
    const res = await fetch(url)
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`)
    }
    return res.json()
  }

  const { data: response = {}, isLoading } = useSWR(url + debounced, fetcher)

  const movies = response?.content || []
  const people = response?.people || []

  const handleSubmit = (e, customValue) => {
    e.preventDefault()
    const query = customValue || value // Usar valor personalizado o el del input
    if (query) {
      router.push(`/search?q=${query}`)
      inputRef.current.blur()
    }
  }

  return (
    <Box component="form" sx={{ width }} onSubmit={handleSubmit} position="relative">
      {isFocused && (
        <Overlay
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            zIndex: 1
          }}
          onClick={() => setIsFocused(false)}
        />
      )}

      <Autocomplete
        placeholder={t('searchPlaceholder')}
        sx={{
          width: '95%',
          borderRadius: '10px',
          boxShadow: '0px -3px 0px 0px rgba(123,57,182,1) inset;',
          position: 'relative',
          zIndex: 2
        }}
        itemComponent={SelectItem}
        label={label}
        name="search"
        data={movies.concat(people)}
        value={value}
        limit={10}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(v) => setValue(v)}
        dropdownComponent={() => (
          <DropdownComponent
            movies={movies}
            people={people}
            inputRef={inputRef}
            searchTerm={value}
            onSubmit={handleSubmit}
          />
        )}
        ref={inputRef}
        filter={() => true}
        rightSection={isLoading ? <Loader size={14} /> : null}
        transition="fade"
        transitionProps={{
          duration: 300
        }}
        icon={<IconSearch size={14} stroke={1.5} />}
      />
    </Box>
  )
}

SearchInput.propTypes = {
  label: PropTypes.string,
  width: PropTypes.string
}

export default SearchInput
